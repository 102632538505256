/** @type {HTMLTemplateElement} */
const folderTemplate = document.querySelector(`body > aside > details > nav > ul > template:first-of-type`);

class FolderElement extends HTMLElement {
	constructor() {
		super();
		this.attachShadow({mode: `open`})
			.appendChild(folderTemplate.content.cloneNode(true));
	}

	/**
	 * @param isOpen {boolean}
	 */
	set isOpen(isOpen) {
		const folderIcon = this.shadowRoot.querySelector(`span:nth-of-type(2)`);
		folderIcon.textContent = isOpen ? `folder_open` : `folder`;
		this.shadowRoot.host.classList.toggle(`open`, isOpen);
	}

	/**
	 * @param name {string}
	 */
	set name(name) {
		const nameElement = this.shadowRoot.querySelector(`span:nth-of-type(3)`);
		nameElement.textContent = name;
	}

	/**
	 * @param unreadCount {number}
	 */
	set unreadCount(unreadCount) {
		const unreadCountElement = this.shadowRoot.querySelector(`span:nth-of-type(4)`);
		unreadCountElement.textContent = unreadCount.toString(10);
		this.shadowRoot.host.classList.toggle(`unread`, unreadCount > 0);
	}
}

customElements.define(`recess-folder`, FolderElement);
